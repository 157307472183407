<template>
  <div
      v-if="children && children.length > 0"
      :key="indexChilds"
      class="space-y-3 max-w-9/10 ml-13 lg:ml-18 pr-3 lg:pr-6"
  >
    <div v-for="(child, index) in children" :key="child.id" :class="'cell-parent-child-' + child.id">
      <div :class="'cell-sub-parent-child-' + child.id">
        <div v-if="child?.title" :class="'cell-parent-child-title-' + child.id" class="flex items-start gap-2">
          <!-- <div
              v-if="item.icon !== undefined"
              class="flex-none w-10 h-10 lg:w-15 lg:h-15 rounded-full flex flex-col items-center justify-center overflow-hidden b-primary b-1"
          >
              <img
                  :src="getThumbnail(item.icon?.id, 400)"
                  :alt="item.icon?.description || item.icon?.title"
                  class="w-full h-full object-cover"
              />
          </div> -->
          <p class="text-sm font-semibold text-bw-11">
            {{ child?.title }}
          </p>
        </div>
        <div
            v-if="child?.notes && child?.notes.length > 0"
            v-for="(noteDes, index) in child?.notes"
            :key="index"
        >
          <div
              v-if="noteDes?.description"
              class="mt-1"
              :class="'cell-parent-child-des-' + index + '-' + child.id"
          >
            <p class="text-xs font-medium text-bw-05">
                            <span v-for="text in upperFirstLetter(noteDes.description)">
                                - {{ text }}
                                <br/>
                            </span>
            </p>
          </div>
        </div>
      </div>
      <div v-if="child.children && child.children.length > 0" class="mt-2">
        <ChildSubFirstCol :children="child.children"/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ChildSubFirstCol from './ChildSubFirstCol'

defineProps({
  children: {
    type: Array as any
  },
  indexChilds: {
    type: Number
  }
})
const upperFirstLetter = (string: string) => {
  if (!string) return ''
  const result = string.replace(/(<([^>]+)>)/gi, '').replaceAll(';', '-')
  return result.split(/\-/)
}
</script>

<style></style>
