<template>
  <!-- <p>{{ getThumbnail(item?.icon, 100) }}</p> -->

  <div class="flex flex-col items-center justify-center">
    <div
        class="w-16 h-16 flex flex-col items-center justify-center rounded-full overflow-hidden b-1 b-solid b-primary mb-3"
    >
      <!-- Icon -->
      <!-- /cms/assets/c95d3ba2-dc0b-445a-9a4b-2ed09928e81c -->
      <img
          :src="getIcon(item.code)"
          :alt="item?.icon?.description || item?.icon?.title"
          class="w-full h-full object-cover"
      />
    </div>
    <p v-html="item?.title" class="text-primary text-sm xl:text-lg font-[550] pb-3 text-center"></p>
    <!-- <p class="text-xs xl:text-base text-black/60 font-medium text-center">
        {{ item?.description }}
    </p> -->
  </div>
</template>

<script setup lang="ts">
import {useProductBenefitIcon} from "../../../composables/products/useProductBenefitIcon";

interface Item {
  item: {
    icon?: { id: string | number; description?: string; title?: string }
    title?: string
    code: string
    benefitType?: string
  }
}

const getIcon = (code: string) => {
  const { getPlanDetailsIcon } = useProductBenefitIcon();

  return getPlanDetailsIcon(code);
};

defineProps<Item>()
</script>

<style></style>
