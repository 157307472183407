<template>
  <section>
    <div class="container pt-10">
      <!-- <div data-aos="fade-up" data-aos-once="true" class="mb-5">
          <GlobalHeading
              title="Sản phẩm này có phù hợp với bạn?"
              opt-key-title="productDetail.titleContact"
          ></GlobalHeading>
      </div> -->

      <!-- <div class="h-1px bg-bw-08 w-full my-5 lg:my-13"></div> -->
      <div class="flex flex-col lg:flex-row justify-between gap-5 lg:gap-10 xl:gap-20">
        <div class="w-full lg:w-3/10" data-aos="fade-up" data-aos-once="true">
          <GlobalHeading
              opt-key-content="productDetail.contentContact"
              :sizeTitle="' lg:text-30px leading-50px'"
              title="Mua trên ứng dụng"
              description="Ứng dụng bảo hiểm IZIon24 sẽ đơn giản hoá mọi thông tin và mang đến sản phẩm phù hợp"
          ></GlobalHeading>
        </div>
        <div class="w-full lg:w-7/10">
          <SectionDownload :linkQr="linkQr"/>
          <!-- <a :href="links_global?.download_app" target="_blank" class="">
              <div data-aos="fade-left" data-aos-nce="true" class="w-full aspect-16/7 hidden lg:block">
                  <v-image
                      :width="1400"
                      option_key="productDetail.imageContactBottom"
                      class="w-full h-full object-cover rounded-15px"
                  ></v-image>
              </div>
              <div
                  data-aos="fade-left"
                  data-aos-once="true"
                  class="lg:hidden aspect-84/161 rounded-xl overflow-hidden mx-auto max-w-xl"
              >
                  <v-image
                      :width="800"
                      option_key="productDetail.imageContactBottomMobile"
                      class="w-full h-full object-cover rounded-15px"
                  ></v-image>
              </div>
          </a> -->
        </div>
      </div>
      <div class="relative flex items-center h-1px w-full bg-bw-08 my-10 lg:my-16">
        <div
            class="absolute right-1/2 transform translate-x-1/2 lg:right-80 xl:right-107 md:left-auto b-1 b-solid b-bw-08 rounded-30px bg-white py-7px px-8 text-bw-26 font-semibold"
        >
          {{ $t('OR_TEXT') }}
        </div>
      </div>
      <div class="relative flex flex-col lg:flex-row justify-between gap-5 lg:gap-20 pb-10">
        <div data-aos="fade-up" data-aos-once="true" class="w-full lg:w-3/10 mt-0 lg:mt-15">
          <GlobalHeading
              :sizeTitle="'lg:text-30px leading-50px'"
              title="Yêu cầu tư vấn"
              opt-key-content="productDetail.contentContactBottom"
              description="Ứng dụng bảo hiểm IZIon24 sẽ đơn giản hoá mọi thông tin và mang đến sản phẩm phù hợp"
          ></GlobalHeading>
        </div>
        <form
            @submit.prevent="onSubmit"
            data-aos="fade-up"
            data-aos-once="true"
            class="form-submit w-full lg:w-7/10 relative px-5 lg:px-13.5 pt-8 lg:pt-12 pb-8 lg:pb-17.5 rounded-20px shadow-lg bg-shadow"
        >
          <!-- <div class="absolute inset-0 -z-1">
              <img src="/images/about/bg-form-contact.png" alt="" class="w-full h-full object-cover" />
          </div> -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-3 lg:gap-y-6">
            <div class="relative z-1">
              <label
                  for="name_contact_product_detail"
                  :class="errorName ? '!text-red-500' : ''"
                  class="text-bw-26 font-semibold text-1px text-13px block pb-2 uppercase"
              >
                {{ $t('FIELD_NAME') }}*
              </label>
              <input
                  id="name_contact_product_detail"
                  name="name"
                  type="text"
                  :class="errorName ? '!placeholder:text-red-500/80 border-red-500' : ''"
                  class="bg-white w-full rounded-28px py-15px px-5.5 b-1 b-solid b-[#CBCBCB] capitalize h-13.5 flex items-center"
                  :placeholder="$t('ENTER_YOUR_FIRST_AND_LAST_NAME')"
                  v-model="infoForm.name"
                  @blur="checkName()"
              />
              <Transition name="fade">
                                <span class="text-red-500 pl-5 mt-2" v-if="errorName">
                                    {{ $t('PLEASE_ENTER_YOUR_NAME') }}
                                </span>
              </Transition>
            </div>

            <div class="relative z-1">
              <label
                  for="phone_contact_product_detail"
                  :class="errorPhone ? '!text-red-500' : ''"
                  class="text-bw-26 font-semibold text-1px text-13px block pb-2 uppercase"
              >
                {{ $t('FIELD_PHONE') }}*
              </label>
              <input
                  id="phone_contact_product_detail"
                  name="phone"
                  type="text"
                  :class="errorPhone ? '!placeholder:text-red-500/80 border-red-500 !text-red-500' : ''"
                  class="bg-white w-full rounded-28px py-15px px-5.5 b-1 b-solid b-[#CBCBCB] h-13.5 flex items-center"
                  :placeholder="$t('ENTER_YOUR_PHONE_NUMBER')"
                  @input="convertedPhoneNumber()"
                  @blur="checkPhone()"
                  v-model="infoForm.phone"
              />
              <Transition name="fade">
                                <span class="text-red-500 pl-5 mt-2" v-if="errorPhone">
                                    {{ $t('INCORRECT_PHONE_NUMBER') }}
                                </span>
              </Transition>
            </div>
            <div class="relative z-1">
              <label class="text-bw-26 font-semibold text-13px block pb-2 uppercase">
                {{ $t('INTERESTED_PRODUCT') }}
              </label>
              <input
                  name="product"
                  :value="titleModel"
                  type="text"
                  class="bg-bw-04/20 w-full rounded-28px py-15px px-5.5 b-1 b-solid b-[#CBCBCB] cursor-not-allowed !text-bw-11/30 h-13.5 flex items-center"
                  disabled
              />
            </div>
            <div class="relative z-1">
              <label
                  for="option_contact_product_detail"
                  class="text-bw-26 font-semibold text-1px text-13px block pb-2 uppercase"
              >{{ $t('FIELD_PRODUCT') }}</label
              >
              <div class="relative flex items-center group">
                <select
                    id="option_contact_product_detail"
                    required
                    class="bg-white w-full rounded-28px py-15px px-5.5 b-1 b-solid b-[#CBCBCB] h-13.5 flex items-center"
                    v-model="infoForm.product_plan"
                >
                  <option v-for="item in dataPlans" :value="item.id" :key="item.id">
                    {{ item.title }}
                  </option>
                </select>

                <span
                    class="absolute i-ic:twotone-keyboard-arrow-down w-5.5 h-5.5 right-4.5 duration-300 z-1 text-[#8C8C8C]"
                ></span>
              </div>
            </div>
          </div>
          <div class="flex flex-col md:flex-row items-center gap-y-5 gap-x-3 mt-6 relative">
            <div class="z-1 w-full md:w-1/2 relative">
              <label
                  for="option_time_contact_product_detail"
                  class="text-bw-26 font-semibold text-1px text-13px block pb-2 uppercase"
              >{{ $t('TIME_TO_CONTACT') }}</label
              >
              <div class="relative flex items-center group">
                <GlobalDateTimePicker
                    id="option_time_contact_product_detail"
                    :min-date="new Date()"
                    v-model="infoForm.call_time"
                    class="bg-white w-full rounded-28px py-13px px-5.5 b-1 b-solid b-[#CBCBCB]"
                ></GlobalDateTimePicker>
                <!-- <vue-date-picker
                    id="option_time_contact_product_detail"
                    v-model="infoForm.call_time"
                    :clearable="false"
                    placeholder="nn/tt/nnnn"
                    text-input
                    class="bg-white w-full rounded-28px py-13px px-5.5 b-1 b-solid b-[#CBCBCB]"
                ></vue-date-picker> -->
                <!-- <input
                    type="datetime-local"

                    v-model="infoForm.call_time"

                /> -->
                <!-- <span
                    class="absolute right-4 i-ic:baseline-calendar-today w-4 h-4 text-[#8C8C8C]"
                ></span> -->
              </div>
            </div>
            <GlobalVerifyForm
                :error="errorChecked"
                v-model:checked="isChecked"
                class="flex md:hidden justify-center"
            />

            <button
                class="relative btn-submit-contact flex-1 bg-primary text-white font-semibold uppercase text-sm block px-11.5 rounded-30px mt-auto flex items-center justify-center py-4 lg:w-max w-full h-13.5 min-h-13.5 flex items-center"
                :class="successSubmit ? '' : 'pointer-events-none'"
            >
              <p v-if="successSubmit">{{ $t('CONTACT_TO_ME') }}</p>
              <div v-else class="absolute loaderForm"></div>
            </button>

            <Transition name="slide-fade">
              <div
                  v-if="statusContact"
                  class="absolute -bottom-15 lg:-bottom-20 xl:-bottom-15 right-0 bg-white shadow-lg py-3 px-4 rounded-lg flex items-center gap-2 z-10 w-full md:w-max"
              >
                <div class="flex gap-2 items-center flex-1">
                  <div
                      class="w-5 h-5 bg-green-600 flex flex-col items-center justify-center rounded-full"
                      v-if="!isErorr"
                  >
                                        <span
                                            class="text-base font-medium i-ic:baseline-check text-xs text-white"
                                        ></span>
                  </div>
                  <div
                      class="w-5 h-5 bg-error-01 flex flex-col items-center justify-center rounded-full"
                      v-else
                  >
                                        <span
                                            class="text-base font-medium i-custom-close2 w-2.5 h-2.5 text-white"
                                        ></span>
                  </div>

                  <span v-if="!isErorr" class="text-sm flex-1">
                                        {{ $t('SUCCESSFULLY_SENT') }}
                                    </span>

                  <span v-else class="text-sm flex-1">
                                        {{ $t('SENDING_REQUEST_FAILED') }}
                                    </span>
                </div>
                <div
                    @click="statusContact = false"
                    class="w-4 h-4 bg-gray-200 hover:bg-gray-300 flex flex-col items-center justify-center rounded-full transform translate-x-2 -translate-y-2 duration-200"
                >
                  <span class="i-custom-close2 text-6px text-bw-24 cursor-pointer"></span>
                </div>
              </div>
            </Transition>
          </div>
          <GlobalVerifyForm
              :error="errorChecked"
              v-model:checked="isChecked"
              class="mt-3 lg:mt-5 md:flex hidden md:justify-start"
          />
        </form>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {useReCaptcha} from 'vue-recaptcha-v3'
import SectionDownload from './SectionDownload.vue'
import {
  PRODUCT_ADVISE_CONTACT_EVENT_ID,
  useEventTracking
} from "../../../composables/ackee/event";

const props: any = defineProps({
  dataCategory: Object,
  model: Object,
  titleModel: String,
  dataPlans: Array,
  idModel: Number,
  linkQr: String
})
const now = ref(new Date())
const statusContact: any = ref(false)
const isErorr = ref(false)
const errorPhone = ref(false)
const errorName = ref(false)
const errorChecked = ref(false)
const isChecked = ref()
const successSubmit = ref(true)
const minutes = ref(String(now.value.getMinutes()).padStart(2, '0'))
const dateTimeDefault = computed(
    () =>
        `${now.value.getFullYear()}-${String(now.value.getMonth() + 1).padStart(2, '0')}-${String(
            now.value.getDate()
        ).padStart(2, '0')}T${String(now.value.getHours()).padStart(2, '0')}:${minutes.value}`
)
const infoForm = ref({
  lang: useRoute().params.langCode,
  name: '',
  phone: '',
  product: props?.idModel,
  product_plan: props?.dataPlans && props?.dataPlans.length > 0 && props?.dataPlans[0]?.id,
  call_time: dateTimeDefault.value
})
const recaptchaInstance = useReCaptcha()
const recaptcha = async () => {
  // optional you can await for the reCaptcha load
  await recaptchaInstance?.recaptchaLoaded()

  // get the token, a custom action could be added as argument to the method
  const token = await recaptchaInstance?.executeRecaptcha('login')

  return token
}

const convertedPhoneNumber = () => {
  if (infoForm.value.phone !== '' && infoForm.value.phone[0] === '0') {
    var convertedPhoneNumber = infoForm.value.phone?.substring(1)
    infoForm.value.phone = '+84' + convertedPhoneNumber
  }
}
const checkPhone = () => {
  var vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g
  if (infoForm.value.phone) {
    var convertedPhoneNumber = infoForm.value.phone.replace(/^\+84/, '0')
    if (vnf_regex.test(convertedPhoneNumber)) {
      errorPhone.value = false
    } else {
      errorPhone.value = true
    }
  } else {
    errorPhone.value = true
  }
}

const checkName = () => {
  if (infoForm.value.name) {
    errorName.value = false
  } else {
    errorName.value = true
  }
}

const checkChecked = () => {
  if (isChecked.value) {
    errorChecked.value = false
  } else {
    errorChecked.value = true
  }
}

watch(
    () => isChecked.value,
    () => {
      checkChecked()
    }
)

const onSubmit = async (): Promise<void> => {
  checkPhone()
  checkName()
  checkChecked()

  useEventTracking(PRODUCT_ADVISE_CONTACT_EVENT_ID, `Product Name: ${props.titleModel} - Product Id: ${infoForm.value.product} - Product plan: ${infoForm.value.product_plan}`)
  if (!errorPhone.value && !errorName.value && !errorChecked.value) {
    successSubmit.value = false
    const token = await recaptcha()
    fetch(`/api/advise?response=${token}`, {
      method: 'POST',
      body: JSON.stringify(infoForm.value)
    })
        .then((response) => response.json())
        .then((data) => {
          successSubmit.value = true
          if (data.code == 201) {
            statusContact.value = true
            isErorr.value = false
            setTimeout(() => {
              statusContact.value = false
            }, 1500)
            infoForm.value = {
              lang: useRoute().params.langCode,
              name: '',
              phone: '',
              product: props?.idModel,
              product_plan: props?.dataPlans[0]?.id,
              call_time: dateTimeDefault.value
            }
          } else {
            statusContact.value = true
            isErorr.value = true
            setTimeout(() => {
              statusContact.value = false
            }, 1500)
          }
        })
        .catch((error) => {
          successSubmit.value = true
        })
  }
}

onMounted(() => {
  setInterval(() => {
    now.value = new Date()
    // infoForm.value.call_time = dateTimeDefault.value
  }, 1000)
})
</script>

<style>
.bg-shadow {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  display: none;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.le-image-img img {
  @apply !object-cover;
}

.dp__input {
  @apply !b-0 !py-0;
}

.dp__input_icons {
  @apply !p-0;
}
</style>
