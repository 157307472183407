<template>
  <BannerDetail :title="dataDetail?.title" :carrier="dataDetail?.carrier" :data="dataDetail"/>
  <SectionCost :data="dataDetail"/>
  <div class="container">
    <div h-1px bg-bw-08 w-full my-8 lg:my-20></div>
  </div>
  <SectionBenefit class="mb-10 xl:mb-15" :data="dataDetail?.benefits"/>
  <TableInfoCostSwiper class="lg:container mt-20" :data="dataDetail"/>

  <SectionTerm class="mt-10" :data="dataDetail"/>
  <SectionDocumentReferences
      :data="dataDetail"
      :dataReferencedDocuments="dataReferencedDocuments"
      :showDocuments="dataDetail?.show_documents"
  />

  <SectionFAQsVue v-if="dataFAQ?.faqs && dataFAQ?.faqs.length > 0" :data="dataFAQ?.faqs"/>
  <SectionFeedback
      v-if="dataDetail && dataDetail.product_usage_cases.length > 0"
      class="mt-10 lg:mt-20"
      :data="dataDetail.product_usage_cases"
      :title="dataDetail?.title"
  />
  <SectionContact
      :idModel="dataDetail?.id"
      :linkQr="dataDetail?.onelink_url"
      :dataPlans="dataDetail?.plans"
      :dataCategory="dataCategory"
      :model="dataDetail?.category"
      :titleModel="dataDetail?.title"
  />
  <div v-if="dataProductRelated && dataProductRelated.length > 0" class="container">
    <div h-1px bg-bw-08 w-full mt-10 mb-10 lg:mt-12 lg:mb-22></div>
  </div>
  <SectionProductRelated :data="dataProductRelated"/>
</template>

<script lang="ts">
export default {
  name: 'productDetail'
}
</script>

<script setup lang="ts">
import BannerDetail from './components/BannerDetail.vue'
import TableInfoCostSwiper from './components/TableInfoCostSwiper.vue'
import SectionCost from './components/SectionCost.vue'
import SectionBenefit from './components/SectionBenefit.vue'
import SectionTerm from './components/SectionTerm.vue'
import SectionDocumentReferences from './components/SectionDocumentReferences.vue'
import SectionFAQsVue from './components/SectionFAQs.vue'
import SectionFeedback from './components/SectionFeedback.vue'
import SectionContact from './components/SectionContact.vue'
import SectionProductRelated from './components/SectionProductRelated.vue'
import {useProducts} from '../../composables/products/index'

import {useGetLangCurrent} from './../../stores/useLanguageMenu'

const {getProductBySlug, getProductsByCategory, getProductsCategory, getReferencedDocuments} = useProducts()
const dataDetail = ref()
const dataFAQ = ref()
const dataCategory = ref()
const dataReferencedDocuments = ref()
const langCode = useRoute().params.langCode

const response = await Promise.all([
  getProductBySlug(useRoute().params.slug, langCode, useSeoFields('products')).then((response) => response),
  getProductsCategory(langCode).then((response) => response)
])
dataDetail.value = response[0]?.model
dataReferencedDocuments.value = await getReferencedDocuments(response[0]?.model, langCode)
dataCategory.value = response[1]

// console.log(`output->dataDetail.value`, dataDetail.value)

const dataProductRelated = await getProductsByCategory(
    // useRoute().params.id,
    response[0]?.model?.id,
    response[0]?.model?.category?.id,
    langCode
)
useDirectusCollectionSeo('products', {
  ...dataDetail.value,
  seo_detail: {
    ...dataDetail.value?.seo_detail
  }
})
const store = useGetLangCurrent()
onMounted(() => {
  store.lang_switch = dataDetail.value?.lang_switch
})
</script>

<style></style>
