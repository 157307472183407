export const planDetailsC0000610Icon = '/images/benefit/icons/plan-details-C0000610-icon.webp';
export const planDetailsC0001630Icon = '/images/benefit/icons/plan-details-C0001630-icon.webp';
export const planDetailsC0001636Icon = '/images/benefit/icons/plan-details-C0001636-icon.webp';
export const planDetailsC0000931Icon = '/images/benefit/icons/plan-details-C0000931-icon.webp';
export const planDetailsC0001631Icon = '/images/benefit/icons/plan-details-C0001631-icon.webp';
export const planDetailsC0001629Icon = '/images/benefit/icons/plan-details-C0001629-icon.webp';
export const planDetailsB00342Icon = '/images/benefit/icons/plan-details-B00342-icon.webp';
export const planDetailsC0001523Icon = '/images/benefit/icons/plan-details-C0001523-icon.webp';
export const planDetailsC0000908Icon = '/images/benefit/icons/plan-details-C0000908-icon.webp';
export const planDetailsC0000932Icon = '/images/benefit/icons/plan-details-C0001636-icon.webp';
export const planDetailsC0000971Icon = '/images/benefit/icons/plan-details-C0000971-icon.webp';
export const planDetailsC0000645Icon = '/images/benefit/icons/plan-details-C0000645-icon.webp';
export const planDetailsC0001635Icon = '/images/benefit/icons/plan-details-C0001635-icon.webp';
export const planDetailsC0001432Icon = '/images/benefit/icons/plan-details-C0001432-icon.webp';
export const planDetailsDefaultNotFoundIcon = '/images/benefit/icons/plan-details-default-icon.webp';
export const planDetailsB00317Icon = '/images/benefit/icons/plan-details-B00317-icon.webp';
export const planDetailsB00323Icon = '/images/benefit/icons/plan-details-B00323-icon.webp';
export const planDetailsB00359Icon = '/images/benefit/icons/plan-details-B00359-icon.webp';
export const planDetailsB00361Icon = '/images/benefit/icons/plan-details-B00361-icon.webp';
export const planDetailsB00362Icon = '/images/benefit/icons/plan-details-B00362-icon.webp';
export const planDetailsB00355Icon = '/images/benefit/icons/plan-details-B00355-icon.webp';
export const planDetailsB00356Icon = '/images/benefit/icons/plan-details-B00356-icon.webp';
export const planDetailsC0001628Icon = '/images/benefit/icons/plan-details-C0001628-icon.webp';
export const planDetailsC0001634Icon = '/images/benefit/icons/plan-details-C0001634-icon.webp';
export const planDetailsC0001633Icon = '/images/benefit/icons/plan-details-C0001633-icon.webp';
export const planDetailsC0001638Icon = '/images/benefit/icons/plan-details-C0001638-icon.webp';
export const planDetailsC0001639Icon = '/images/benefit/icons/plan-details-C0001639-icon.webp';
export const planDetailsC0001645Icon = '/images/benefit/icons/plan-details-C0001645-icon.webp';
export const planDetailsC0001686Icon = '/images/benefit/icons/plan-details-C0001686-icon.png';
export const planDetailsC0001709Icon = '/images/benefit/icons/plan-details-C0001709-icon.png';
export const planDetailsC0001702Icon = '/images/benefit/icons/plan-details-C0001702-icon.png';
export const planDetailsC0001705Icon = '/images/benefit/icons/plan-details-C0001705-icon.png';
export const planDetailsC0001704Icon = '/images/benefit/icons/plan-details-C0001704-icon.png';
export const planDetailsC0001715Icon = '/images/benefit/icons/plan-details-C0001715-icon.webp';
export const planDetailsC0001716Icon = '/images/benefit/icons/plan-details-C0001716-icon.webp';
export const planDetailsC0001717Icon = '/images/benefit/icons/plan-details-C0001717-icon.webp';
export const planDetailsC0001718Icon = '/images/benefit/icons/plan-details-C0001718-icon.webp';
export const planDetailsC0001719Icon = '/images/benefit/icons/plan-details-C0001719-icon.webp';
export const planDetailsC0001720Icon = '/images/benefit/icons/plan-details-C0001720-icon.webp';
export const planDetailsC0001721Icon = '/images/benefit/icons/plan-details-C0001721-icon.webp';
export const planDetailsC0001722Icon = '/images/benefit/icons/plan-details-C0001722-icon.webp';
export const planDetailsC0001723Icon = '/images/benefit/icons/plan-details-C0001723-icon.webp';
export const planDetailsC0001724Icon = '/images/benefit/icons/plan-details-C0001724-icon.webp';
export const planDetailsC0001746Icon = '/images/benefit/icons/plan-details-C0001746-icon.webp';
export const planDetailsC0000612Icon = '/images/benefit/icons/plan-details-C0000612-icon.webp';
export const planDetailsC0000613Icon = '/images/benefit/icons/plan-details-C0000613-icon.webp';
export const planDetailsC0000614Icon = '/images/benefit/icons/plan-details-C0000614-icon.webp';
export const planDetailsC0000615Icon = '/images/benefit/icons/plan-details-C0000615-icon.webp';
export const planDetailsC0000616Icon = '/images/benefit/icons/plan-details-C0000616-icon.webp';
export const planDetailsC0000617Icon = '/images/benefit/icons/plan-details-C0000617-icon.webp';
export const planDetailsC0000620Icon = '/images/benefit/icons/plan-details-C0000620-icon.webp';
export const planDetailsC0001710Icon = '/images/benefit/icons/plan-details-C0001710-icon.png';
export const travelAssistanceIcon = '/images/benefit/icons/travel-assistance.png';
export const travelVisitationIcon = '/images/benefit/icons/visitation.png';
export const benefitGolfPrivilegesIcon = '/images/benefit/icons/benefit-golf-privileges.png';
export const travelJourneyIcon = '/images/benefit/icons/journey.png';
export const benefitExtensionIcon = '/images/benefit/icons/benefit-extension.png';
export const planDetailsC0001745Icon = '/images/benefit/icons/plan-details-C0001745-icon.webp';
export const defaultNotFoundIcon = '/images/benefit/icons/not-found-default.png';

