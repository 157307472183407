<template>
  <section>
    <div class="container">
      <div class="mb-10" data-aos="fade-right" data-aos-once="true">
        <GlobalHeading
            :title="'Quyền lợi bạn nhận được'"
            opt-key-title="productDetail.titleBenefit"
        ></GlobalHeading>
      </div>
      <Swiper
          :modules="[SwiperAutoplay, SwiperPagination, SwiperNavigation]"
          :space-between="20"
          :autoHeight="true"
          :navigation="{
                    nextEl: '.next-swiper-product',
                    prevEl: '.prev-swiper-product'
                }"
          :centeredSlidesBounds="true"
          :pagination="{
                    clickable: true,
                    dynamicBullets: true
                }"
          :autoplay="{
                    delay: 10000,
                    disableOnInteraction: false
                }"
          :breakpoints="{
                    300: { slidesPerView: 1 },
                    800: { slidesPerView: 2 },
                    1040: { slidesPerView: 3 }
                }"
          :grabCursor="true"
          v-if="data && data.length > 0"
          data-aos="fade-up"
          data-aos-once="true"
          class="swiper-benefit !pb-10 !h-auto"
      >
        <SwiperSlide class="!h-auto !mt-0 mb-5" v-for="item in filteredData" :key="item?.id">
          <WrapperBenefit :item="item"/>
        </SwiperSlide>
      </Swiper>

      <!-- <WrapperBenefit
              data-aos="fade-up"
              data-aos-once="true"
              v-for="item in data"
              :key="item?.id"
              :item="item"
          /> -->
    </div>
  </section>
</template>

<script setup lang="ts">
import WrapperBenefit from './WrapperBenefit.vue'
import 'swiper/css/pagination'

interface item {
  id: string | number
  icon?: { id: string | number; description?: string; title?: string }
  title: string
  code: string
  benefitType?: any
}

interface Props {
  data?: item[]
}

const props = defineProps<Props>()

const filteredData = computed(() => {
  return props.data?.filter((item: any) => {
    return item.icon.title && !item.icon.title.includes("Plan Details Default Icon")
  }) || []
})
</script>

<style>
.swiper-benefit .swiper-pagination {
  @apply bottom-0 !w-28.5;
}

.swiper-benefit .swiper-pagination > .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply !w-6.5;
}
</style>
