<template>
  <div class="relative py-7 xl:py-10 rounded-xl bg-wrapper h-58 lg:h-auto flex flex-col">
    <div class="relative overflow-hidden py-5 px-7 lg:px-10 xl:px-12.5 pb-23">
      <p
          class="relative max-w-70 text-2xl xl:text-30px text-white font-[550] leading-7 xl:leading-11 z-10 line-clamp-3"
      >
        {{ item?.title }}
      </p>

      <div class="absolute top-0 -right-30">
        <img src="/images/about/pattern-reason.svg" alt="pattern-reason" class="w-full h-ful object-cover"/>
      </div>
    </div>

    <div class="px-7 lg:px-10 xl:px-12.5 relative z-100 mt-auto">
      <button
          v-if="item?.url?.terms_links || item?.url?.exclusion_links"
          class="relative z-10 bg-white text-accent-01 text-sm font-semibold w-full rounded-30px max-w-47 px-3 btn-effect"
      >
        <div
            @click="showDropwdown = true"
            class="flex items-center justify-center gap-2 relative z-10 uppercase py-3"
        >
          <p>{{ item?.textLink }}</p>

          <p class="transform text-accent-01 w-5.5 h-5.5 i-custom-chevron-down"></p>
        </div>
      </button>
      <button
          v-else
          @click="$emit('openPopupPreview', { item: item?.url ? item?.url[0] : '', status: true })"
          class="relative z-10 inline-block uppercase px-11.5 py-3 rounded-30px bg-white text-accent-01 btn-effect text-sm font-semibold"
      >
        <p>{{ item?.textLink }}</p>
      </button>
      <div
          :class="showDropwdown ? 'translate-y-0 opacity-100' : 'translate-y-5 opacity-0'"
          class="absolute top-full left-7 lg:left-10 h-fit py-2 px-4 w-8/10 lg:w-7/10 transform bg-white rounded-xl duration-200 z-10 overflow-hidden text-black mt-2 shadow-lg z-100"
      >
        <div
            @click.stop="showDropwdown = false"
            class="w-5 h-5 rounded-full flex flex-col items-center justify-center bg-bw-08 hover:bg-bw-04/50 mr-auto text-bw-15 hover:text-white cursor-pointer transform !-translate-x-2.5"
        >
          <p class="i-custom-close w-4 h-4"></p>
        </div>

        <ul class="text-left space-y-1 pt-2 pb-1">
          <li
              v-if="item?.url?.terms_links?.link"
              @click="$emit('openPopupPreview', { item: item?.url?.terms_links?.link, status: true })"
              class="flex items-center gap-2 bg-primary rounded-md pl-1 py-2 hover:bg-accent-01 duration-200 cursor-pointer"
          >
            <span class="i-ic:baseline-insert-drive-file w-4 h-4 bg-white flex-none"></span>
            <p class="text-xs xl:text-sm line-clamp-1 text-white">{{ $t('TERM_AND_CONDITION') }}</p>
          </li>

          <li
              v-if="item?.url?.exclusion_links?.link"
              @click="$emit('openPopupPreview', { item: item?.url?.exclusion_links?.link, status: true })"
              class="cursor-pointer flex items-center gap-2 bg-primary rounded-md pl-1 py-2 hover:bg-accent-01 duration-200"
          >
            <span class="i-ic:baseline-insert-drive-file w-4 h-4 bg-white flex-none"></span>
            <p class="text-xs xl:text-sm line-clamp-1 text-white">{{ $t('EXCLUSION_CLAUSE') }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  item: {
    type: Object
  }
})
const showDropwdown = ref(false)
</script>

<style scoped>
.bg-wrapper {
  background: radial-gradient(247.42% 100.06% at 32.37% 100%, #ffa17d 5.03%, #ff8050 42.75%, #ed5a22 100%);
}
</style>
