<template>
  <section>
    <!-- <pre>{{ data }}</pre> -->
    <div class="container mt-12 lg:mt-20 overflow-hidden">
      <div class="flex flex-col lg:flex-row justify-between gap-7.5 md:gap-15 xl:gap-35">
        <div data-aos="fade-right" data-aos-once="true" class="w-full lg:w-6/12 xl:w-6/12">
          <div v-if="data?.content" class="section-cost-product post-content" v-html="data?.content"></div>
        </div>
        <div
            data-aos="fade-left"
            data-aos-once="true"
            class="w-full lg:w-6/12 xl:w-6/12 bg-final-05 rounded-15px p-7.5 lg:p-10"
        >
          <div v-for="(item, index) in infoCost">
            <div class="flex justify-between gap-6 xl:gap-12.5">
              <p class="text-black/60 font-semibold text-sm xl:text-base whitespace-nowrap">
                {{ item.title }}
              </p>
              <p class="text-sm xl:text-base text-primary font-semibold text-right">
                {{ item.content }}
              </p>
            </div>
            <div v-if="index < 3" class="h-1px w-full bg-black/10 my-6"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const props: any = defineProps({
  data: {
    type: Object
  }
})
const maxInsuredPeriod = ref(props?.data?.max_insured_period?.value ?? '')
const minInsuredPeriod = ref(props?.data?.min_insured_period?.value ?? '')
const typeMinInsuredPeriod = ref(props?.data?.min_insured_period?.type_insured_period ?? '')
const typeMaxInsuredPeriod = ref(props?.data?.max_insured_period?.type_insured_period ?? '')
const checkManyNumber = (number: number) => {
  return number > 1 && useRoute().params.lang == 'en' ? 's' : ''
}
const checkContentrInsured = computed(() => {
  if (maxInsuredPeriod.value === minInsuredPeriod.value && typeMaxInsuredPeriod.value === typeMinInsuredPeriod.value)
    return `${props?.data?.min_insured_period?.insured_period} ${t(
        typeMinInsuredPeriod.value.toUpperCase()
    )}${checkManyNumber(props?.data?.min_insured_period?.insured_period)}`
  else if (
      maxInsuredPeriod.value !== minInsuredPeriod.value &&
      typeMaxInsuredPeriod.value == typeMinInsuredPeriod.value
  ) {
    return `${props?.data?.min_insured_period?.insured_period} - ${
        props?.data?.max_insured_period?.insured_period
    } ${t(typeMinInsuredPeriod.value.toUpperCase())}${checkManyNumber(
        props?.data?.min_insured_period?.insured_period
    )}`
  }
  return `${props?.data?.min_insured_period?.insured_period} ${
      t(typeMinInsuredPeriod.value.toUpperCase()) + checkManyNumber(props?.data?.min_insured_period?.insured_period)
  } - ${props?.data?.max_insured_period?.insured_period} ${
      t(props?.data.max_insured_period?.type_insured_period.toUpperCase()) +
      checkManyNumber(props?.data?.max_insured_period?.insured_period)
  }`
})
//console.log(props?.data)
const infoCost = [
  {
    title: t('INSURANCE_FEES'),
    content: props?.data?.premium
  },
  {
    title: t('TOTAL_FEES'),
    content: props?.data?.sum_insured
  },
  {
    title: t('INSURANCE_COMPANY'),
    content: props?.data?.carrier?.title
  },
  {
    title: t('TIME_INSURANCE'),
    content: checkContentrInsured.value
  }
]
</script>

<style></style>
