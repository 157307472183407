<template>
  <section>
    <div class="container">
      <div
          data-aos="fade-up"
          data-aos-once="true"
          class="relative rounded-15px bg-wrapper-product py-5 xl:pt-10 pr-7 lg:pr-11 pl-7 lg:pl-15"
      >
        <Swiper
            :effect="'fade'"
            :fadeEffect="{ crossFade: true }"
            :modules="[SwiperAutoplay, SwiperNavigation, SwiperThumbs, SwiperEffectCreative, SwiperEffectFade]"
            :space-between="100"
            :slidesPerView="1"
            :grabCursor="true"
            :navigation="{
                        prevEl: '.prev-swiper-product-feedback',
                        nextEl: '.next-swiper-product-feedback'
                    }"
            :loop="true"
            :thumbs="{ swiper: thumbsSwiper }"
            class="relative z-10 h-full"
        >
          <SwiperSlide v-for="(item, index) in data" :key="index" class="!h-auto !py-2">
            <div class="flex flex-col lg:flex-row justify-between gap-5 lg:gap-20 h-full">
              <div class="w-full lg:w-1/2 lg:py-10">
                <p class="uppercase text-white/70 text-8px lg:text-xs xl:text-base font-semibold">
                  {{ $t('EXAMPLE_FEEDBACK') }}
                </p>
                <p
                    class="text-white text-2xl lg:text-4xl xl:text-5xl font-[550] lg:pb-8 lg:leading-13 py-3 lg:py-0 mt-1"
                >
                  {{ $t('TEXT_SITUATION') }}
                </p>
                <!-- <div class="space-0.5 lg:space-y-5px">
                    <p>
                        <span class="text-white/60 text-xs lg:text-base xl:text-lg"
                            >{{ $t('AGE_FEEDBACK') }}:
                        </span>
                        <span class="text-white text-sm lg:text-base xl:text-lg font-medium"
                            >{{ item?.age }} tuổi</span
                        >
                    </p>
                    <p>
                        <span class="text-white/60 text-xs lg:text-base xl:text-lg"
                            >{{ $t('JOB_FEEDBACK') }}:
                        </span>
                        <span class="text-white text-xs lg:text-base xl:text-lg font-medium">{{
                            item?.job
                        }}</span>
                    </p>
                    <p>
                        <span class="text-white/60 text-xs lg:text-base"
                            >{{ $t('PRODUCT_FEEDBACK') }}:
                        </span>
                        <span class="text-white text-xs lg:text-base xl:text-lg font-medium">{{
                            title
                        }}</span>
                    </p>
                </div> -->

                <div
                    v-html="item?.content"
                    class="text-white/90 text-sm lg:text-base xl:text-lg content-testimonial"
                ></div>
              </div>

              <div
                  class="flex flex-1 relative z-10 max-w-xs md:max-w-md xl:max-w-full sm:mx-auto md:h-full w-full lg:w-1/2"
              >
                <div class="w-full h-full rounded-xl aspect-1/1">
                  <img
                      :src="getThumbnail(item?.image?.id, 800, true)"
                      :alt="item?.image?.description || item?.image?.title"
                      class="w-full h-full object-cover rounded-xl object-top"
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div
            v-if="data && data.length > 1"
            class="absolute left-0 top-1/2 flex justify-between items-center w-full transform -translate-y-1/2 z-10"
        >
          <button
              class="prev-swiper-product-feedback transform -translate-x-4 md:-translate-x-6 xl:-translate-x-7 w-10 h-10 lg:w-12 lg:h-12 xl:w-15 xl:h-15 rounded-full hidden flex flex-col items-center justify-center bg-final-03 shadow-lg duration-200 hover:bg-primary text-primary hover:text-white duration-200"
          >
            <span class="i-ic:sharp-keyboard-arrow-left text-3xl"></span>
          </button>

          <button
              class="next-swiper-product-feedback transform translate-x-4 md:translate-x-6 xl:translate-x-7 w-10 h-10 lg:w-12 lg:h-12 xl:w-15 xl:h-15 rounded-full hidden flex flex-col items-center justify-center bg-final-03 shadow-lg hover:bg-primary text-primary hover:text-white duration-200"
          >
            <span class="i-ic:sharp-keyboard-arrow-right text-3xl"></span>
          </button>
        </div>
        <!-- <div class="hidden lg:block absolute bottom-10 left-15 lg:max-w-113 z-10">
            <Swiper
                @swiper="setThumbsSwiper"
                :spaceBetween="10"
                :breakpoints="{
                    1024: { slidesPerView: 7 },
                    624: { slidesPerView: 6 },
                    320: { slidesPerView: 6 }
                }"
                :freeMode="true"
                :loop="true"
                :watchSlidesProgress="true"
                :modules="[SwiperFreeMode, SwiperThumbs]"
                class="swiper-product-feedback"
            >
                <SwiperSlide
                    v-for="(item, index) in data"
                    :key="index"
                    class="cursor-pointer group flex-none !w-auto !mr-1 !lg:mr-3"
                >
                    <div
                        class="w-9 h-9 lg:w-12 lg:h-12 rounded-full flex flex-col items-center justify-center overflow-hidden2 bg-accent-01"
                    >
                        <img
                            :src="getThumbnail(item.image, 100)"
                            alt=""
                            class="w-full h-full object-cover transform group-hover:scale-120 duration-400"
                        />
                    </div>
                </SwiperSlide>
            </Swiper>
        </div> -->
        <div class="absolute bottom-0 -left-2 z-1 w-7/10 h-full">
          <div
              class="i-custom-circle-menu w-5/10 h-full bg-circle-primary opacity-30 transform translate-x-40"
          ></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const props = defineProps({
  data: {
    type: Array
  },
  title: {
    type: String
  }
})
let thumbsSwiper = ref(null)
const setThumbsSwiper = (swiper: any) => {
  thumbsSwiper.value = swiper
}
</script>

<style>
.bg-wrapper-product {
  background: radial-gradient(162.55% 98.99% at 73.6% 64.69%, #ffa17d 5.03%, #ff8050 42.75%, #ed5a22 100%);
}

.swiper-product-feedback .swiper-slide-thumb-active {
  @apply relative overflow-hidden;
}

.swiper-product-feedback .swiper-slide-thumb-active::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-style: solid;
  border-width: 3px;
  border-color: transparent;
  background: linear-gradient(97.99deg, #fe210c 12.1%, #febd29 84.58%) border-box; /*3*/
  -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}

.swiper-product-feedback .swiper-wrapper {
  @apply !flex !flex-row !items-end !justify-end !gap-2.5;
}

.swiper-product-feedback .swiper-wrapper > .swiper-slide {
  @apply relative z-10;
}

.swiper-product-feedback .swiper-wrapper > .swiper-slide::after {
  content: '';
  @apply absolute inset-0 w-full h-full rounded-full bg-primary/50 z-100;
}

.swiper-product-feedback .swiper-wrapper > .swiper-slide.swiper-slide-thumb-active::after {
  content: '';
  @apply absolute inset-0 w-12 h-12 rounded-full bg-transparent z-100;
}

.content-testimonial p {
  @apply line-clamp-10;
}
</style>
