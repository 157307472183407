<template>
  <section class="">
    <div v-if="matchData && matchData.length > 0" class="container my-10 lg:my-18 relative z-10">
      <div data-aos="fade-right" data-aos-once="true">
        <GlobalHeading
            class="lg:max-w-110"
            opt-key-content="productDetail.contentDocument"
            title="Tài liệu tham khảo"
            description="Ứng dụng bảo hiểm IZIon24 sẽ đơn giản hoá mọi thông tin và mang đến sản phẩm phù hợp nhất với nhu cầu của riêng bạn. Hãy trải nghiệm việc mua bảo hiểm."
        ></GlobalHeading>
      </div>
      <div
          data-aos="fade-up"
          data-aos-once="true"
          class="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-5 relative"
      >
        <WrapperDocument
            @openPopupPreview="handlePopupPreview"
            v-for="(item, index) in matchData"
            :item="item"
            :key="index"
        />
      </div>
    </div>
  </section>
  <Teleport v-if="isLoaded" to="body">
    <PopupPreviewDoc v-model:visible="isOpen" :dataDownload="dataDownload"/>
  </Teleport>
</template>

<script setup lang="ts">
import WrapperDocument from './WrapperDocument.vue'
import PopupPreviewDoc from './PopupPreviewDoc.vue'

const {t} = useI18n()
const props = defineProps({
  data: {
    type: Object
  },
  showDocuments: {
    type: Array
  },
  dataReferencedDocuments: {
    type: Object
  }
})

const isOpen = ref(false)
const dataDocumentDefault = [
  {
    key: 'brochure_download',
    title: t('DOWNLOAD_FEE_SCHEDULE'),
    textLink: t('VIEW_DOCUMENT'),
    url: props?.data?.brochure_download ? [props?.data?.brochure_download] : null
  },
  {
    key: 'term_download',
    title: t('DOWNLOAD_RULES_AND_TERMS'),
    textLink: t('VIEW_DOCUMENT'),
    url: props?.data?.term_download ? [props?.data?.term_download] : props.dataReferencedDocuments
  },
  {
    key: 'compensation_guide',
    title: t('SEE_COMPENSATION_GUIDE'),
    textLink: t('VIEW_DOCUMENT'),
    url: props?.data?.compensation_guide ? [props?.data?.compensation_guide] : null
  }
]
// console.log(
//     '🚀 ~ file: SectionDocumentReferences.vue:67 ~ props?.data?.compensation_download:',
//     props?.data?.compensation_guide
// )
const dataDownload = ref()
const matchData = dataDocumentDefault.filter((item: any) => {
  return props.showDocuments?.find((data: any) => {
    if (data == item.key) return item
  })
})
//console.log('🚀 ~ file: SectionDocumentReferences.vue:79 ~ matchData ~ matchData:', matchData)
const handlePopupPreview = (data: any) => {
  isOpen.value = data.status
  dataDownload.value = data.item
}
const isLoaded = ref(false)
onMounted(() => {
  isLoaded.value = true
})
</script>

<style></style>
