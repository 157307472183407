<template>
  <section class="">
    <div
        class="relative h-full rounded-15px"
        style="
                background: radial-gradient(
                    162.55% 98.99% at 73.6% 64.69%,
                    #ffa17d 5.03%,
                    #ff8050 42.75%,
                    #ed5a22 100%
                );
            "
    >
      <div class="absolute top-20 right-0 z-1 lg:block hidden overflow-hidden">
        <img class="w-full h-full object-contain" src="/images/circle/hinh1.svg" alt="circle"/>
      </div>
      <div class="bottom-0 right-0 absolute z-1 lg:hidden block">
        <img class="w-full h-full object-contain" src="/images/circle/hinh3.svg" alt="circle"/>
      </div>
      <div class="gap-5 lg:gap-12 pl-5 lg:pl-12 xl:pl-18 flex lg:flex-row flex-col w-full relative z-10">
        <div class="lg:w-1/2 w-full py-5 xl:py-10">
          <div data-aos="fade-down-right" data-aos-once="true" class="lg:mb-5 lg:mb-20">
            <v-content
                class="font-[550] text-white text-xl sm:text-2xl lg:text-28px leading-40px xl:text-35px xl:leading-44px"
                default_value="Tải ứng dụng IZIon24 ngay <p class='text-16px leading-6 w-full lg:max-w-101 text-white/95 mt-4 font-normal'>IZION24 nuôi ước vọng mang đến sự bảo vệ đáng tin cậy cho người Việt thông quanhững ứng.</p>"
                option_key="product.contentBannerDownload"
            />
          </div>
          <div
              data-aos="fade-up"
              data-aos-once="true"
              class="flex md:flex-row flex-col gap-2 lg:gap-6 md:divide-x-1 md:divide-white/40"
          >
            <div class="flex flex-col gap-2.5">
              <div class="block">
                <v-text
                    tag="p"
                    default_value="Tải trực tiếp từ:"
                    option_key="subTitleBanerDownload1.global"
                    class="text-white/60 font-semibold text-10px lg:text-sm leading-6 uppercase"
                />
              </div>
              <div class="space-y-2.5">
                <a
                    :href="links_global?.appstore"
                    @click="useEventTracking(DOWNLOAD_EVENT_ID, DOWNLOAD_APP_ON_APP_STORE_KEY)"
                    target="_blank"
                    class="block w-37.5 aspect-38/12 overflow-hidden"
                >
                  <img
                      class="w-full h-full object-contain rounded-2"
                      src="/images/global/appstore.svg"
                      alt="appstore"
                  />
                </a>
                <a
                    :href="links_global?.google_play"
                    @click="useEventTracking(DOWNLOAD_EVENT_ID, DOWNLOAD_APP_ON_GOOGLE_PLAY_KEY)"
                    target="_blank"
                    class="block w-37.5 aspect-38/12 overflow-hidden"
                >
                  <img
                      class="w-full h-full object-contain rounded-2"
                      src="/images/global/googlePlay.svg"
                      alt="googleplay"
                  />
                </a>
              </div>
            </div>
            <div class="md:pl-6 space-y-2.5">
              <div class="block">
                <v-text
                    tag="p"
                    default_value="quét mã qr:"
                    option_key="subTitleBanerDownload2.global"
                    class="text-white/60 font-semibold text-10px lg:text-sm leading-6 uppercase"
                />
              </div>
              <div v-if="linkQr" class="block">
                <div class="w-27 aspect-1/1 overflow-hidden">
                  <a :href="linkQr" target="_blank" class="qrcode-download-product">
                    <vue-qrcode :value="linkQr"></vue-qrcode>
                  </a>
                </div>
              </div>
              <div v-else class="block">
                <div class="w-27 aspect-1/1 overflow-hidden">
                  <img
                      :src="getThumbnail(links_global?.qr, 162, true)"
                      alt="qr"
                      class="w-full h-full object-contain rounded-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="zoom-in" data-aos-once="true" class="flex-1 w-full lg:w-1/2 flex pr-5">
          <div class="aspect-1/1 w-full h-8/10 mt-auto image-contain img-download">
            <v-image
                option_key="product.imageBannerDownload"
                class="w-full h-full object-contain"
            ></v-image>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import VueQrcode from '@chenfengyuan/vue-qrcode'
import {
  DOWNLOAD_EVENT_ID,
  DOWNLOAD_APP_ON_APP_STORE_KEY,
  DOWNLOAD_APP_ON_GOOGLE_PLAY_KEY,
  useEventTracking
} from '../../../composables/ackee/event'

const props = defineProps({
  linkQr: {
    type: String
  }
})
const links_global = inject('globalSettings')?.links[0]
const nuxtApp = useNuxtApp()
nuxtApp.vueApp.component(VueQrcode.name, VueQrcode)
</script>

<style>
.qrcode-download-product canvas {
  @apply !w-full !h-full rounded-2;
}

.img-download > div {
  @apply !w-full !h-full;
}
</style>
