<template>
  <div class="relative aspect-2/3 sm:aspect-3/2 md:aspect-2/1 xl:aspect-360/140 lg:aspect-380/140 overflow-hidden">
    <div class="absolute inset-0 z-1">
      <picture>
        <source
            media="(max-width:758px)"
            :srcset="getThumbnail(data?.banner_mobile?.id, 800)"
            :alt="data?.banner_mobile?.description || data?.banner_mobile?.title"
            class="w-full h-full object-cover"
        />
        <img
            :src="getThumbnail(data?.banner_desktop?.id, 1920, true)"
            :alt="data?.banner_desktop?.description || data?.banner_desktop?.title"
            class="w-full h-full object-cover"
        />
      </picture>
      <!-- <img src="/images/products/banner-detail.png" alt="" class="w-full h-full object-cover" /> -->
    </div>
    <div class="bg-wrapper-banner absolute inset-0 z-1"></div>
    <div class="hidden md:block absolute left-0 bottom-0 z-1 w-4/10 h-full">
      <div class="i-custom-circle-menu w-9/10 h-full bg-circle-primary opacity-30"></div>
    </div>
    <!-- If button share exist pb-35 -->
    <!-- Else pb-15 -->
    <div class="relative z-10 container flex flex-col justify-end h-full pt-10 pb-25 lg:pt-0 lg:pb-22">
      <div data-aos="fade-right" data-aos-once="true">
        <h1
            class="text-white/90 lg:text-white/60 text-xs lg:text-sm xl:text-base font-semibold leading-6 uppercase dop-shadow-lg shadow-black"
        >
          {{ $t('INSURANCE_PRODUCTS') }}
        </h1>
        <h2
            class="leading-12 lg:leading-14 xl:leading-17 text-43px lg:text-[50px] xl:text-[60px] font-[550] text-white mt-1.5 lg:mt-3.5 max-w-152 dop-shadow-lg shadow-black line-clamp-4"
        >
          {{ title }}
        </h2>
        <a :href="carrier?.url" target="_blank" class="mt-6 lg:mt-8.5 flex items-center gap-4 inline-flex">
          <div class="flex-none w-13 h-13 xl:w-20 xl:h-20">
            <img
                :src="getThumbnail(carrier?.thumbnail?.id, 160)"
                :alt="carrier?.thumbnail?.description || carrier?.thumbnail?.title"
                class="w-full h-full object-contain"
            />
          </div>
          <div class="">
            <p class="text-white/70 lg:text-sm xl:text-base">{{ $t('COMPANY') }}</p>
            <p class="lg:text-15px xl:text-lg font-[550] text-white">{{ carrier?.title }}</p>
          </div>
        </a>
      </div>

      <button
          @click="handleCopyLink()"
          data-aos="zoom-in"
          data-aos-once="true"
          class="absolute bottom-8 lg:bottom-20 left-5 lg:left-auto lg:right-0 bg-white btn-effect rounded-30px py-3 lg:py-2.5 px-8 lg:px-7 flex items-center gap-1"
      >
        <span class="uppercase text-sm font-semibold text-primary">{{ $t('SHARE_NOW') }}</span>
        <span class="i-ic:baseline-share text-xs lg:text-lg text-primary"></span>
      </button>
      <Teleport v-if="isLoaded" to="body">
        <GlobalPopupCopySuccess v-model:isOpen="copyLink"/>
      </Teleport>
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  title: {
    type: String
  },
  carrier: {
    type: Object
  },
  data: {
    type: Object
  }
})
const shareLinkFacebook = ref()
const href = ref()
const copyToClipboard = (url: any) => {
  var sampleTextarea = document.createElement('textarea')
  document.body.appendChild(sampleTextarea)
  sampleTextarea.value = url //save main text in it
  sampleTextarea.select() //select textarea contenrs
  document.execCommand('copy')
  document.body.removeChild(sampleTextarea)
}
const copyLink = ref(false)
const handleCopyLink = () => {
  copyLink.value = true
  copyToClipboard(href.value)
  setTimeout(() => {
    copyLink.value = false
  }, 5000)
}
const isLoaded = ref(false)

onMounted(() => {
  isLoaded.value = true

  href.value = window.location.href
  shareLinkFacebook.value = `https://www.facebook.com/sharer/sharer.php?u=${href.value} `
})
</script>
<style scoped>
.bg-wrapper-banner {
  width: 60%;
  background: linear-gradient(90deg, #ff8050 59.75%, rgba(255, 128, 80, 0) 100%);
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .bg-wrapper-banner {
    width: 100%;
    background: linear-gradient(0deg, #ff8050 30.75%, rgba(255, 128, 80, 0) 70%);
    flex-shrink: 0;
  }
}

.icon-white {
  filter: brightness(0) invert(1);
}
</style>
