<template>
  <div
      v-if="children && children.length > 0"
      :key="indexChilds"
      class="space-y-2 max-w-9/10 ml-2 lg:ml-3 pr-3 lg:pr-6"
  >
    <div v-for="(child, index) in children" :key="child.id" :class="'cell-parent-child-' + child.id">
      <div :class="'cell-sub-parent-child-' + child.id">
        <div v-if="child?.title" :class="'cell-parent-child-title-' + child.id" class="flex items-start gap-2">
          <p class="text-sm font-semibold text-bw-11">
            {{ child?.title }}
          </p>
        </div>
        <div
            v-if="child?.notes && child?.notes.length > 0"
            v-for="(noteDes, index) in child?.notes"
            :key="index"
        >
          <div
              v-if="noteDes?.description"
              :class="'cell-parent-child-des-' + index + '-' + child.id"
              class="mt-1"
          >
            <p class="text-xs font-medium text-bw-05">
                            <span v-for="text in upperFirstLetter(noteDes.description)">
                                - {{ text }}
                                <br/>
                            </span>
            </p>
          </div>
        </div>
      </div>
      <div v-if="child.children && child.children.length > 0" class="mt-2">
        <ChildSubFirstCol :children="child.children"/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  children: {
    type: Array as any
  },
  indexChilds: {
    type: Number
  }
})
const upperFirstLetter = (string: string) => {
  if (!string) return ''
  const result = string.replace(/(<([^>]+)>)/gi, '').replaceAll(';', '-')
  return result.split(/\-/)
}
</script>

<style></style>
