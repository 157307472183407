<template>
  <v-popup
      overlay
      closeOverlay
      onOpenLoadPage
      keyPopup="popupPreviewDoc"
      v-model:visible="isVisible"
      :closeable="false"
  >
    <template #header class="mb-7.5 relative">
            <span
                class="i-custom-close2 w-4 h-4 text-bw-04 absolute z-5 -top-7 right-0 lg:-right-6.5 cursor-pointer hover:text-bw-02"
                @click=";[$emit('update:visible', false), (isVisible = false)]"
            ></span>
      <div v-if="loading" class="absolute top-1/2 left-1/2 transform -translate-1/2">
        <div class="loaderForm w-12 h-12"></div>
      </div>

      <!-- <div class="flex items-center justify-between py-2 px-4 gap-3">
          <p class="max-w-lg font-medium text-sm leading-4 lg:text-lg">
              Lorem ipsum dolor sit amet consectetur.pdf Lorem
          </p>
          <a
              :href="dataDownload"
              target="_blank"
              class="py-2 px-4 rounded-lg bg-primary text-white btn-effect flex items-center gap-2"
              download
          >
              <span class="i-ic:round-sim-card-download text-xl text-white"></span>
              <p class="text-sm lg:text-lg">Download</p>
          </a>
      </div> -->
    </template>
    <p v-if="!dataDownload" class="container text-2xl text-bw-04 text-center">{{ $t('NO_DOCUMENT') }}</p>
    <iframe v-if="dataDownload" :src="dataDownload" frameborder="0" height="100%" width="100%" @load="checkLoad">
    </iframe>
  </v-popup>
</template>

<script lang="ts">
export default {}
</script>

<script setup lang="ts">
const props = defineProps({
  dataDownload: String
})
const isVisible = ref(false)

const loading = computed(() => {
  if (!props.dataDownload) return false
  if (isVisible.value) return false
  return true
})
const checkLoad = () => {
  isVisible.value = true
}
</script>

<style>
.box-content-popup-popupPreviewDoc {
  @apply w-full !max-w-full h-60vh lg:h-80vh !p-0;
}

.box-content-popup-popupPreviewDoc .content-popup {
  @apply h-full flex flex-col items-center justify-center;
}
</style>
