<template>
  <section v-if="infoCost?.eligible_person || infoCost?.exception_person" class="bg-wrapper py-10 lg:py-25">
    <div class="container">
      <div class="flex flex-col lg:flex-row gap-5 lg:gap-25">
        <div data-aos="fade-right" data-aos-once="true">
          <GlobalHeading
              class="lg:max-w-110"
              opt-key-content="productDetail.contentTerm"
              :title="'Điều khoản & Điều kiện'"
              :description="'IZION24 nuôi ước vọng mang đến sự bảo vệ đáng tin cậy cho người Việt thông qua những ứng.'"
              light
          ></GlobalHeading>
          <div
              data-aos="zoom-out"
              data-aos-once="true"
              class="hidden lg:block mx-auto w-40 md:w-65 image-cover aspect-1/1"
          >
            <v-image option_key="productDetail.imageTerm"></v-image>
          </div>
        </div>
        <div
            v-if="infoCost?.eligible_person && infoCost?.eligible_person.length > 0"
            data-aos="fade-left"
            data-aos-once="true"
            class="relative overflow-hidden flex-1 bg-final-05 rounded-30px p-10"
        >
          <div class="flex gap-12 lg:gap-27.5">
            <p class="text-black font-[550] text-17px lg:text-xl w-full max-w-32.5">
              {{ $t('ELIGIBLE_PERSON') }}
            </p>

            <div class="divide-y divide-bw-08 space-y-4.5 text-left">
              <div
                  v-for="(child, index) in infoCost?.eligible_person"
                  class="text-15px text-black/60"
                  :class="index > 0 ? 'pt-4' : 'pt-0'"
                  v-html="child"
              ></div>
            </div>
          </div>
          <div class="h-1px w-full bg-black/10 my-6"></div>

          <div
              v-if="infoCost.exception_person && infoCost.exception_person.length > 0"
              class="flex gap-12 lg:gap-27.5"
          >
            <p class="text-black font-[550] text-17px lg:text-xl w-full max-w-32.5">
              {{ $t('EXCEPTION_PERSON') }}
            </p>

            <div class="divide-y divide-bw-08 space-y-4.5 text-left">
              <div
                  v-for="(child, index) in infoCost.exception_person"
                  class="text-15px text-black/60"
                  :class="index > 0 ? 'pt-4' : 'pt-0'"
                  v-html="child"
              ></div>
            </div>
          </div>

          <div class="absolute -bottom-50 -left-30 z-1">
            <img
                src="/images/about/pattern-reason.svg"
                alt="pattern-reason"
                class="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const props = defineProps({
  data: {
    type: Object
  }
})
const infoCost = computed(() => {
  return props.data?.policies
})
</script>

<style scoped>
.bg-wrapper {
  background: radial-gradient(162.55% 98.99% at 73.6% 64.69%, #ffa17d 5.03%, #ff8050 42.75%, #ed5a22 100%);
}
</style>
