import {Logger} from "../../../utils/logger.utils";

import {
  travelAssistanceIcon,
  travelVisitationIcon,
  travelJourneyIcon,
  benefitGolfPrivilegesIcon,
  benefitExtensionIcon,
  planDetailsB00317Icon,
  planDetailsB00323Icon,
  planDetailsB00342Icon,
  planDetailsB00355Icon,
  planDetailsB00356Icon,
  planDetailsB00359Icon,
  planDetailsB00361Icon,
  planDetailsB00362Icon,
  planDetailsC0000610Icon,
  planDetailsC0000645Icon,
  planDetailsC0000908Icon,
  planDetailsC0000931Icon,
  planDetailsC0000932Icon,
  planDetailsC0000971Icon,
  planDetailsC0001432Icon,
  planDetailsC0001523Icon,
  planDetailsC0001628Icon,
  planDetailsC0001629Icon,
  planDetailsC0001630Icon,
  planDetailsC0001631Icon,
  planDetailsC0001633Icon,
  planDetailsC0001634Icon,
  planDetailsC0001635Icon,
  planDetailsC0001636Icon,
  planDetailsC0001638Icon,
  planDetailsC0001639Icon,
  planDetailsC0001645Icon,
  planDetailsC0001686Icon,
  planDetailsC0001702Icon,
  planDetailsC0001704Icon,
  planDetailsC0001705Icon,
  planDetailsC0001709Icon,
  planDetailsC0001710Icon,
  planDetailsDefaultNotFoundIcon,
  planDetailsC0001745Icon,
  planDetailsC0001715Icon,
  planDetailsC0001716Icon,
  planDetailsC0001717Icon,
  planDetailsC0001718Icon,
  planDetailsC0001719Icon,
  planDetailsC0001720Icon,
  planDetailsC0001721Icon,
  planDetailsC0001722Icon,
  planDetailsC0001723Icon,
  planDetailsC0001724Icon,
  planDetailsC0000612Icon,
  planDetailsC0000613Icon,
  planDetailsC0000614Icon,
  planDetailsC0000615Icon,
  planDetailsC0000616Icon,
  planDetailsC0000617Icon,
  planDetailsC0000620Icon,
  planDetailsC0001746Icon,
  defaultNotFoundIcon
} from '../../constants/assets.constant';

export enum BenefitTypeEnum {
  BTRider = 'R',
  BTRiderChild = 'RP',
  BTRiderBenefit = 'RB',
  SubRiderBenefit = 'SRB',
}

export const EXCLUDE_BENEFITS: BenefitTypeEnum[] = [
  BenefitTypeEnum.BTRider,
  BenefitTypeEnum.BTRiderChild,
  BenefitTypeEnum.BTRiderBenefit,
  BenefitTypeEnum.SubRiderBenefit
];

export const useProductBenefitIcon = () => {
  const runtimeConfigs = useRuntimeConfig();
  const logger = Logger(runtimeConfigs.log_level);

  const getPlanDetailsIcon = (benefitCode: string) => {
    switch (benefitCode) {
      case 'C0000612':
        return planDetailsC0000612Icon;
      case 'C0000613':
        return planDetailsC0000613Icon;
      case 'C0000614':
        return planDetailsC0000614Icon;
      case 'C0000615':
        return planDetailsC0000615Icon;
      case 'C0000616':
        return planDetailsC0000616Icon;
      case 'C0000617':
        return planDetailsC0000617Icon;
      case 'C0000620':
        return planDetailsC0000620Icon;
      case 'C0000610':
      case 'C0000622':
      case 'C0000623':
        return planDetailsC0000610Icon;
      case 'C0001630':
        return planDetailsC0001633Icon;
      case 'C0001636':
        return planDetailsC0001636Icon;
      case 'C0000931':
        return planDetailsC0000931Icon;
      case 'C0001631':
      case 'C0001648':
        return planDetailsC0001631Icon;
      case 'C0001629':
        return planDetailsC0001629Icon;
      case 'B00342':
      case 'C0001684':
        return planDetailsB00342Icon;
      case 'C0001523':
        return planDetailsC0001523Icon;
      case 'C0000908':
        return planDetailsC0000908Icon;
      case 'C0000932':
      case 'C0001713':
        return planDetailsC0000932Icon;
      case 'C0000971':
        return planDetailsC0000971Icon;
      case 'C0000645':
        return planDetailsC0000645Icon;
      case 'C0001635':
        return planDetailsC0001635Icon;
      case 'C0000626': // PA_OPES
      case 'C0001432':
        return planDetailsC0001432Icon;
      case 'C0001637':
      case 'C0001692':
        return planDetailsC0000971Icon;
      case 'B00317':
        return planDetailsB00317Icon;
      case 'B00323':
        return planDetailsB00323Icon;
      case 'B00359':
        return planDetailsB00359Icon;
      case 'B00360':
        return planDetailsB00359Icon;
      case 'B00361':
        return planDetailsB00361Icon;
      case 'B00362':
        return planDetailsB00362Icon;
      case 'B00355':
      case 'C0000611':
        return planDetailsB00355Icon;
      case 'B00357':
        return planDetailsB00355Icon;
      case 'B00356':
      case 'C0001682':
        return planDetailsB00356Icon;
      case 'C0001632':
        return planDetailsC0000971Icon;
      case 'C0001628':
        return planDetailsC0001628Icon;
      case 'C0001634':
      case 'C0001642':
      case 'C0001685':
      case 'C0001712':
        return planDetailsC0001634Icon;
      case 'C0001633':
        return planDetailsC0001633Icon;
      case 'C0001643':
        return planDetailsC0001630Icon;
      case 'C0001649':
      case 'C0000624':
        return planDetailsC0001633Icon;
      case 'C0001644':
      case 'C0001747':
        return planDetailsC0001629Icon;
      case 'C0001510':
        return planDetailsC0000908Icon;
      case 'C0001638':
        return planDetailsC0001638Icon;
      case 'C0001639':
        return planDetailsC0001639Icon;
      case 'C0000599':
        return planDetailsC0000610Icon;
      case 'C0001645':
      case 'C0001688':
      case 'C0001714':
        return planDetailsC0001645Icon;
      case 'C0001686':
        return planDetailsC0001686Icon;
      case 'C0001687':
        return planDetailsB00317Icon;
      case 'C0001689':
        return planDetailsC0001638Icon;
      case 'C0001709':
      case 'C0001706':
      case 'C0001703':
      case 'C0001707':
      case 'C0001706':
        return planDetailsC0001709Icon;
      case 'C0001702':
        return planDetailsC0001702Icon;
      case 'C0001705':
        return planDetailsC0001705Icon;
      case 'C0001704':
        return planDetailsC0001704Icon;
      case 'C0001708':
        return planDetailsC0000971Icon;
      case 'C0001710':
      case 'C0001711':
        return planDetailsC0001710Icon;
      case 'EMRGNCY_MEDI_HLPLINE':
        return travelAssistanceIcon;
      case 'C0000619':
        return travelJourneyIcon;
      case 'C0000621':
        return travelJourneyIcon;
      case 'TRIP_CURTAIL':
        return travelJourneyIcon;
      case 'C100699':
        return travelJourneyIcon;
      case 'MISD_CONXN':
        return travelJourneyIcon;
      case 'LOSS_PRSL_BLNGS':
        return planDetailsC0000908Icon;
      case 'C100700':
        return planDetailsC0000908Icon;
      case 'C0000995':
        return planDetailsC0000908Icon;
      case 'BAG_DELAY':
        return planDetailsC0000908Icon;
      case 'LOSS_TRVL_DOC':
        return planDetailsC0000908Icon;
      case 'C0001164':
        return planDetailsC0000908Icon;
      case 'C0000515':
        return planDetailsC0001635Icon;
      case 'HOSP_CASH_OVRSEAS':
        return planDetailsC0001635Icon;
      case 'C100701':
        return planDetailsC0001635Icon;
      case 'EMRGNCY_PHNE':
        return planDetailsC0001635Icon;
      case 'C100702':
        return travelVisitationIcon;
      case 'C100708':
        return planDetailsC0000610Icon;
      case 'C100707':
        return planDetailsC0000610Icon;
      case 'C100703':
        return benefitGolfPrivilegesIcon;
      case 'C0000627': // PA_OPES
      case 'AUTO_POL_XTN':
        return benefitExtensionIcon;
      case 'C100705':
        return benefitExtensionIcon;
      case 'C100706':
        return benefitExtensionIcon;
      case 'RENT_VEH_XS':
        return benefitExtensionIcon;
      case 'PRSL_LIAB':
        return benefitExtensionIcon;
      case 'C0001149':
        return benefitExtensionIcon;
      case 'BAG_DELAY':
        return benefitExtensionIcon;
      case 'LOSS_TRVL_DOC':
        return benefitExtensionIcon;
      case 'C0001164':
        return benefitExtensionIcon;
      case 'C0000515':
        return benefitExtensionIcon;
      case 'HOSP_CASH_OVRSEAS':
        return benefitExtensionIcon;
      case 'C100701':
        return benefitExtensionIcon;
      case 'EMRGNCY_PHNE':
        return benefitExtensionIcon;
      case 'C100702':
        return benefitExtensionIcon;
      case 'C100708':
        return benefitExtensionIcon;
      case 'C100707':
        return benefitExtensionIcon;
      case 'C100703':
        return benefitExtensionIcon;
      case 'C100705':
        return benefitExtensionIcon;
      case 'C100706':
        return benefitExtensionIcon;
      case 'RENT_VEH_XS':
        return benefitExtensionIcon;
      case 'PRSL_LIAB':
        return benefitExtensionIcon;
      case 'C0001149':
        return benefitExtensionIcon;
      case 'C0001683':
        return planDetailsC0000971Icon;
      case 'C0001745':
      case 'C0001758':
        return planDetailsC0001745Icon;
      case 'C0001715':
        return planDetailsC0001715Icon;
      case 'C0001716':
        return planDetailsC0001716Icon;
      case 'C0001717':
        return planDetailsC0001717Icon;
      case 'C0001718':
        return planDetailsC0001718Icon;
      case 'C0001719':
        return planDetailsC0001719Icon;
      case 'C0001720':
        return planDetailsC0001720Icon;
      case 'C0001721':
        return planDetailsC0001721Icon;
      case 'C0001722':
        return planDetailsC0001722Icon;
      case 'C0001723':
        return planDetailsC0001723Icon;
      case 'C0001724':
        return planDetailsC0001724Icon;
      case 'C000001':
        return planDetailsB00355Icon;
      case 'C0001746':
        return planDetailsC0001746Icon;
      case 'C0001749':
        return planDetailsB00323Icon;
      case 'C0001748':
        return planDetailsC0000610Icon;

      // SEP
      case 'C0001752':
      case 'B00500':
        return planDetailsC0001630Icon;

      case 'C0000625': // PA_OPES
      case 'C0001753':
        return planDetailsC0001633Icon;
      case 'C0001754':
      case 'B00501':
        return planDetailsC0001636Icon;

      case 'C0001755':
      case 'B00502':
        return planDetailsC0000931Icon;

      case 'C0001756':
      case 'B00504':
        return planDetailsC0001631Icon;
      case 'C0001757':
        return planDetailsC0001629Icon;

      default:
        return planDetailsDefaultNotFoundIcon;
    }
  };

  return {
    getPlanDetailsIcon
  }
}